import React from 'react'

import {
  Layout,
  SEO,
  CaseStudy,
  Showcase,
  Carousel,
  Metrics,
  Iframe,
} from '../components'

import smarthome from '../images/smarthome.gif'
import signaturePreview from '../images/capturing_signature.png'
import designerPreview from '../images/designer_view.png'
import iconRibbon from '../images/ribbon.svg'
import iconClock from '../images/clock.svg'
import iconCalendar from '../images/calendar.svg'
import socialCard from '../images/social_card_slateplan.png'

function getCarouselImages() {
  const requireContext = require.context(
    '../images/carousel_slateplan',
    true,
    /\.(png|jpe?g|svg)$/
  )
  const requireContextKeys = requireContext.keys()
  return requireContextKeys.map((key) => requireContext(key))
}

const carouselImages = getCarouselImages()

const resultData = [
  {
    title: 'Days to complete the MVP',
    value: '100',
    backgroundIcon: iconCalendar,
  },
  {
    title: 'Cedia best in show award 2015',
    value: '#1',
    backgroundIcon: iconRibbon,
  },
  {
    title: 'Loading time after optimization',
    value: '2s',
    backgroundIcon: iconClock,
  },
]

const Slateplan = ({ location }) => (
  <Layout location={location}>
    <SEO
      title="Slateplan"
      image={socialCard}
      description="An award-winning tool used to budget and bid on smart home designs. GNAR helped build Slateplan’s team to create their first product."
    />
    <CaseStudy
      projectName="Slateplan"
      descriptionTitle="The Problem"
      description="Slateplan needed help with developing a better way to bid the budget and scope of a smart home. They hired GNAR to help them create their MVP."
      previewImage={smarthome}
      showcase
    />
    <Showcase
      title="Capturing a signature"
      description="A client can sign and approve plans by writing directly on a touch surface or by using a mouse or track pad."
      image={signaturePreview}
      direction="normal"
    />
    <Showcase
      title="Optimizing the Designer View"
      description="GNAR dramatically improved load times for the designer view from over 10 seconds to under 2 seconds."
      image={designerPreview}
      direction="inverted"
    />
    <Carousel title="Finished UI" images={carouselImages} />
    <Iframe url="https://www.youtube.com/embed/fpbaONXCw6U" />
    <Metrics
      title="The Result"
      description="Slateplan won a Best in Show award and after 100 days, Slateplan was selling their product."
      data={resultData}
    />
  </Layout>
)

export default Slateplan
